form {

    label {
        float: left;
        line-height: 1.25em;
        margin: 0 8px 0 0;
        padding: 6px 0;
        text-align: right;
        width: 150px;
        
        @include media-breakpoint-down(md) {
            width: auto;
            position: absolute;
            top: -25px;
        }
    }
    input, textarea {
        border: 1px solid #d3d3ce;
        color: #403f41;
        padding: 5px;
        width: 50%;
        margin-right: 5px;

        @include media-breakpoint-down(md) {
            width: 90%;
        }
    }

    .row {
        clear: both;
        display: block;
        margin: 0.5em 0;
        padding: 0.25em 0;

        @include media-breakpoint-down(md) {
            position: relative;
            margin: 2em 0;
        }
    }

    .btn-green {
        padding: 0.3em 9px 0.3em;
        background-color: $evergreen;
        border-color: $evergreen;
        border-style: solid;
        border-width: 1px;
        color: $white;
        cursor: pointer;
        margin-right: 3px;
        font-weight: bold;
        position: absolute;
        left: 24%;
        width: 20%;

        &:hover {
            color: $white;
        }

        @include media-breakpoint-down(md) {
            width: 90%;
            position: relative;
            left: 0;
        } 


    }

    .error {
        background-color: $pink;
    }
}