header {
        height: 140px;
    @include media-breakpoint-down(sm){
        height: 100px;
    }
    #logo {
        position: absolute;
        top: 0;
        width: 150px;
        height: 100px;
        padding: 0;
        
        @include media-breakpoint-down(md){
            width: 120px;
        }
        @include media-breakpoint-down(sm){
            position: absolute;
            width: 110px;
            top: 5px;
            left: 15px;
        }
    }	
}