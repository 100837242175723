body {
    line-height: normal;
}

.dark-green-full-width-bar {
    height: 5px;
    background-color: $dark-green;
    width: 100%; 
}

.social-icons {
    margin-bottom: .5em;
    float: left;
    margin-right: 1em;
    color: $charcoal;
    font-size: 1.1em;
    border-right: 1px solid $dark-grey;
    padding-right: 1em;
}

#disclaimer {
    font-size: .9em;
    color: #aaa;
    float: left;
    text-align: left;
}