
.navbar {
    padding: 5px 0 0 0;
    font-family: 'GillSansLight';
    color: $white;

    .navbar-collapse {
        @include media-breakpoint-down(sm){
            margin-top: 90px;
            z-index: 2;
        }
    }

    .navbar-toggler {
        border: 2px solid $dark-green;
        position: absolute;
        right: 15px;
        top: 30px;
        height: 50.5px;
    }
    
    .navbar-toggler-icon {  
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(43,139,124, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }
    .nav-item {
        margin-left: 0;

        @include media-breakpoint-up(md){
            margin-left: 3px;
        
            &.has-children {
                    
                    &.selected {

                        .nav-link {
                            border-radius: 0;
                            padding-bottom: 11px;
                            background-color: $medium-green;
                        }
                    }

                    &:hover {
                
                        .children {
                            left: auto;
                        }
                
                    }
                
                .nav-link {
                    &:hover {
                        border-radius: 0;
                    }
                }
            }
            .children {
                list-style: none;
                position: absolute;
                left: -999999px;
                width: 200px;
                padding-left: 0;
                z-index: 10;
                top: 100%;

                .nav-item {
                    margin-left: 0;
                
                    .nav-link {
                        border-radius: 0;
                        font-size: 1.2em;
                        background: $medium-green;
                        padding-top: 7px;

                        &:hover {
                            background-color: $dark-green;
                        }
                    }
                }
            }
        }

        .nav-link {
            display: block;
            color: $white;
            font-size: 1.5em;
            padding: 5px 15px 8px;
            border-radius: 0 0 7px 7px;
            background: $light-green;

            &:visited, 
            &:hover,
            &:focus {
                color: $white;
            }

            @include media-breakpoint-down(sm){
                border-radius: 0;
            }


            &:hover {
                background-color: $medium-green;
                color: $white;
            }
        }
        &.active {
            > .nav-link:first-of-type {
                color: $white;
                background-color: $dark-green;
                font-weight: bold;
            }
        }
    }
            @include media-breakpoint-down(sm){
                .has-children {

                    &.open {
                
                        > .nav-link {
                            background-color: $medium-green;

                        }
                    
                        .children {
                            display: block;
                        }
                    }
                
                    .children {
                        padding-left: 0;
                        display: none;
                    
                    .nav-link {
                        padding-left: 25px;
                        font-size: 1.35em;
                    }

                }

            }

        }
}