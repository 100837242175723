h1 {
    color: $dark-green;
    font-size: 3em;
    font-weight: normal;
    font-family: 'GillSansLight';
}

p {
    padding: 0.5em 0 1em 0;
    line-height: 1.5em;
    color: $charcoal;
    margin-bottom: 0;
}

a {
    color: $dark-green;

    &:hover {
        color: $light-green;
        text-decoration: none;
    }
}
