.highlight {
    font-size: 1.335em;
    color: $light-green;
}

.sidebar-heading {
    color: $black;
    border-bottom: 2px solid $light-green;
    font-weight: normal;
    font-size: 1.167em;
    padding: 0.5em 0;
}

.bold-uppercase-green {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.335em;
    color: $light-green;
    margin-top: 0.5rem;
}