// Bootstrap

	// Border

		$border-radius: 0;


	// Font
		$font-size-base: 0.75rem;
		$font-family-base: 'Lucida Sans Unicode', 'Lucida Grande', 'sans-serif';

	// Forms

		$input-font-size-lg: 1rem;

	// Colour

		$primary: $charcoal;
		$secondary: $dark-green;
		$tertiary: $light-green;

//container
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen
  lg: 992px,
  // Extra Large screen
  xl: 1200px
);

$container-max-widths: (
  sm: 576,
  md: 768px,
  lg: 960px,
  xl: 980px
);
